@import "../../styles/uiBase.scss";

.popup {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
  background-color: hsl(0, 0%, 16%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
  border-top: 2px solid $colorAccentPrimary;
  z-index: 2;

  @include mobileVW() {
    flex-wrap: wrap;
    padding: 1rem;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  p {
    align-self: center;
    margin-right: 2rem;

    @include mobileVW() {
      margin-right: 0;
    }
  }

  .btns {
    @include mobileVW() {
      width: 100%;
      margin-top: 1rem;
    }
  }
}
