@import "../../styles/uiBase.scss";

.auth {
}

.authFormWrapper {
}

.authForm {
  width: 100%;
}

.formHeading {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;

  @include mobileVW() {
    font-size: 2rem;
  }
}

.errorMsg {
  color: $colorDanger;
  margin: 1rem 0;
}

.formBtns {
  margin-top: 1rem;
}

.formSwitchBlurb,
.forgotPass {
  display: block;
  text-align: center;
}

.formSwitchBlurb button,
.forgotPass {
  &:hover {
    text-decoration: underline;
  }
}

.formSwitchBlurb {
  margin-top: 1rem;

  button {
    color: $colorAccentSecondary;
  }
}

.forgotPass {
  margin-top: 1.5rem;
  color: $colorAccentPrimary;
}
