@import "../../styles/uiBase.scss";

.layout,
.lowerContent {
  display: flex;
  height: 100%;
  position: relative;
}

.layout {
  flex-direction: column;
}

.lowerContent {
  overflow: hidden;
}

.mainContent {
  flex-grow: 1;
  position: relative;

  @include mobileVW() {
    width: 100%;
  }
}
