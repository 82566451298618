@import "../../styles/uiBase.scss";

.header {
  margin-bottom: 2rem;

  h1,
  p {
  }

  &.alignLeft {
    h1 {
      text-align: left;
    }
  }

  h1 {
    margin-bottom: 0.5rem;
    text-align: center;
  }

  p {
    color: $textSecondary;
  }
}
