@import "../../styles/uiBase.scss";

.userDash {
  position: relative;
  max-width: none;

  h2,
  .pageHeader {
    margin-top: 3rem;
    margin-bottom: 2rem;

    @include largeVW() {
      margin-bottom: 0;
    }

    &.account {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  h2 {
    text-align: center;
  }
}

.fixedWidthContent {
  max-width: 40rem;
  margin: 0 auto;
}

.errorMsg {
  color: $colorDanger;
  margin: 1rem 0;
}

.subDetails {
  .detail {
    margin-top: 1rem;
    font-size: 1.5rem;
    line-height: 1.75rem;

    &.tier {
    }
  }
}

.alert {
  margin-top: 2rem;

  &.incorrectInfo {
    margin-bottom: 4rem;
  }

  .content {
    color: $textSecondary;

    a {
      color: $colorAccentSecondary;
    }
  }
}

.manageSubWrapper {
  margin: 1rem auto 2rem auto;
  max-width: 40rem;

  @include mobileVW() {
    margin-bottom: 1rem;
  }

  .manageBtn {
    @include mobileVW() {
      width: 100%;
    }
  }
}

.table {
  margin-top: 1rem;
}
