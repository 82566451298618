@import "../../styles/uiBase.scss";

.mainNav {
  position: relative;
  z-index: 15;
  transition: all 0.25s;
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background-color: $bodyBgPrimary;
  box-shadow: $boxShadowDark;

  .rightContent {
    display: flex;
  }
}

$navIconHeight: 1.7rem;

.homeBtn,
.mobileNavBtn,
.msgNavIcon {
  flex-shrink: 0;
  width: $navIconHeight;
}

.chatBtn {
  flex-shrink: 0;
  padding: 0.4rem 0.75rem;
}

.homeBtn {
  &:hover {
    text-decoration: none;
  }

  span {
    display: none;
  }

  @include notMobileVW() {
    display: flex;
    align-items: center;
    width: initial;
    height: 2rem;

    span {
      display: initial;
      font-weight: bold;
      color: $textAccentSecondary;
    }

    .icon {
      height: 100%;
      margin-right: 0.5rem;
    }
  }
}

.homeBtn,
.msgNavIcon {
  .icon {
    font-size: 1.5rem;
  }
}

.msgNavIcon {
  &:hover .icon {
    color: $textPrimary;
  }

  .icon {
    color: hsl(0, 0, 70%);
    transition: all 0.15s;
  }
}

.mobileNavBtn {
  display: none;

  @include mobileVW() {
    display: flex;
    justify-content: flex-end;
  }

  .icon {
    font-size: 1.5rem;
    transition: all 0.25s;

    &.close {
      transform: rotate(90deg);
    }
  }
}

.chatName {
  display: none;
  position: relative;
  flex-grow: 1;
  padding: 0 1rem;
  min-width: 0;
  overflow: hidden;
  font-weight: bold;
  color: $textPrimary;

  @include mobileVW() {
    display: block;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2rem;
    background: linear-gradient(
      to right,
      rgba($bodyBgPrimary, 0),
      $bodyBgPrimary
    );
  }

  p {
    white-space: nowrap;
    text-align: center;
  }
}
