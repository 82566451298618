@import "../../styles/uiBase.scss";

$tooltipBg: hsl(0, 0%, 15%);

.tooltip {
  position: absolute;
  z-index: 5;
  top: -0.75rem;
  left: 50%;
  width: 80vw;
  max-width: 15rem;
  transform: translate(-50%, -100%);
  background-color: $tooltipBg;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 1rem 0 rgba(black, 1);
  border: 1px solid $colorAccentSecondary;
  transition: all 0.25s;

  @include mobileVW() {
    padding: 0.75rem;
    max-width: none;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    background-color: $tooltipBg;
    bottom: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 0.125rem;
    left: 50%;
    transform: translate(-50%, 56%) rotate(45deg);
    border: 2px solid $colorAccentSecondary;
    border-left: none;
    border-top: none;
  }

  .content {
    position: relative;
    z-index: 2;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $textSecondary;
  }
}

.tooltipWrapper {
  position: relative;
  display: inline-block;
}

.questionIcon {
  font-size: 1rem;
  color: $colorAccentPrimary;
  transition: all 0.2s;

  &:hover {
    color: hsl(0, 0%, 50%);
  }
}
