@import "../../styles/uiBase.scss";

.home {
  .btn {
    width: 100%;
    padding: 0.75rem 1rem;
  }

  .section {
    margin: 2rem 0 1rem 0;

    .heading {
      font-size: 1.5rem;
    }
  }
}
