@import "../../styles/uiBase.scss";

.noMatch {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem 0 1rem;

  .title {
    text-align: center;
  }

  .cta {
    text-align: center;
    color: $colorAccentSecondary;
  }
}
