@import "../../styles/uiBase.scss";

.home {
  padding: 0 0 4rem 0;
  width: 100%;
  margin: 0 auto;
  max-width: none;

  .pageTitle {
    text-align: center;
  }

  .startChatForm {
  }
}

.contentWrapper {
  width: 90%;
  max-width: 60rem;
  margin: 0 auto;
}

.header {
  margin-bottom: 0;
}
.title {
  font-size: 2.5rem;

  @include mobileVW() {
    font-size: 1.5rem;
  }
}

.desc {
  text-align: center;
}

.desc,
.blurb {
  @include notMobileVW() {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

.homeSection,
.desktopImg {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

$sectionSidePadding: 7rem;

.homeSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobileVW() {
    margin-bottom: 5rem;
  }

  @include notMobileVW() {
    width: 90%;
    margin: 0 auto 10rem auto;
  }

  @include mobileVW() {
    padding-top: 100%;
    background-position: top;
  }

  &.alignImageLeft {
    flex-direction: row-reverse;

    .content {
      padding-left: $sectionSidePadding;
      padding-right: 0;

      @include mobileVW() {
        padding-left: 0;
      }
    }
  }

  .desktopImgWrapper {
    width: 40rem;
    flex-shrink: 0;

    @include largeVW() {
      width: 50%;
    }

    @include mobileVW() {
      display: none;
    }

    .desktopImg {
      // TODO: Make padding specific to each section
      padding-top: 96%;
    }
  }

  .content {
    // width: 45%;
    flex-grow: 1;
    padding-right: $sectionSidePadding;

    @include mobileVW() {
      padding: 1rem 0 0 0;
      width: 90%;
      flex-grow: initial;
      margin: 0 auto;
    }

    h2 {
    }

    .blurb {
      margin: 0.5rem 0 1rem 0;
      max-width: 60rem;
    }
  }
}

.hero {
  position: relative;
  background: linear-gradient(to bottom, $bodyBgPrimary, hsl(0, 0%, 6%));
  padding: 15rem 0 15rem 0;
  margin-bottom: 5rem;

  @include mobileVW() {
    margin-bottom: 3rem;
    padding: 100% 0 2rem 0;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://personaverse.com/appLogo.svg");
    background-position: right 19% top 50%;
    background-size: auto 90%;
    background-repeat: no-repeat;

    @include largeVW() {
      background-position: right 5% top 50%;
    }

    @include mobileVW() {
      background-size: 73% auto;
      background-position: top 10% right 50%;
    }
  }

  .inner {
    width: 90%;
    margin: 0 auto;
    position: relative;
  }

  .content {
    width: 50%;

    @include mobileVW() {
      width: 100%;
    }

    h1 {
      font-size: 3.5rem;
      line-height: 3.75rem;

      @include largeVW() {
        font-size: 2.5rem;
        line-height: 2.75rem;
      }
    }

    .blurb {
      font-size: 2rem;
      line-height: 2.25rem;
      margin-top: 0.25rem;
      color: $colorAccentSecondary;

      @include largeVW() {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }
}

.startChattingCta {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  margin-top: 1rem;

  @include mobileVW() {
    width: 100%;
  }
}

.pricingTable {
  margin: 5rem 0;
  width: 90%;
  margin: 0 auto;
}
