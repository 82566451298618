@import "../../styles/uiBase.scss";

.footer {
  padding: 1rem 0;
  background-color: rgba(white, 0.025);
}

.content {
  width: 90%;
  margin: 0 auto;
}

.upperContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.socials {
  a {
    font-size: 2rem;

    @include mobileVW() {
      font-size: 1.75rem;
    }

    &:hover {
      .icon {
        color: $textPrimary;
      }
    }

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    .icon {
      color: rgba($textPrimary, 0.7);
      transition: all 0.15s;
    }
  }
}

$linkColor: rgba($textPrimary, 0.6);

.support {
  color: $linkColor;
  transition: all 0.15s;

  &:hover {
    color: $textPrimary;
    text-decoration: none;
  }
}

.legalLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include largeVW() {
    margin-top: 1rem;
  }

  div {
    margin: 0 1rem;

    @include largeVW() {
      margin-top: 1rem;
    }
  }

  a,
  p {
    color: $linkColor;
    transition: all 0.15s;
  }

  a:hover {
    color: $textPrimary;
    text-decoration: none;
  }
}
