@import "../../../styles/uiBase.scss";

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bodyBgPrimary;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  z-index: 10;

  &.loading {
    opacity: 1;
    pointer-events: all;
    transition: all 0s;
  }
}

.loadingText {
  width: 100%;
  text-align: center;
  margin-top: 0.75rem;
  color: $textSecondary;
}
